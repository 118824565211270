<template>
  <div>
    <general-table
      :api-url="APIURL"
      :add-component-name="addComponentName"
      :edit-component="editComponent"
      :delete-content="false"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :add-type="addType"
      :selectable="false"
      :columns="columns"
      :type="type"
    >
      <template #cell(type)="data">

        {{ getTypes(data.item.type) }}

      </template>
      <template
        #cell(is_active)="data"
      >
        <b-badge
          class="change-status"
          :variant="changeVaraint(data.item.is_active)"
        >
          {{ getStatus(data.item.is_active) }}
        </b-badge>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    return {
      APIURL: 'admin/spin_gifts',
      addType: 'Add Spin gift',
      addComponentName: 'add-spin-gifts',
      editComponent: 'edit-spin-gifts',
      viewContent: true,
      type: 'page',
      columns: [
        { key: 'id', sortable: false },
        { key: 'image', label: 'Image' },
        { key: 'name', label: 'Name' },
        { key: 'description_en', label: 'Description' },
        { key: 'type', label: 'Type' },
        { key: 'value', label: 'Value' },
        { key: 'num_of_winners', label: 'No. Of Winners' },
        { key: 'is_active', label: 'Active' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type === 1) return 'success'

      return 'danger'
    },
    getStatus(type) {
      if (type === 1) {
        return 'Active'
      } if (type === 0) {
        return 'Not Active'
      }
      return 'Active'
    },
    getTypes(type) {
      if (type === 1) {
        return 'Dealmart Wallet'
      } if (type === 2) {
        return 'Zerocash Wallet'
      }
      if (type === 3) {
        return 'Promo Code '
      }
      if (type === 4) {
        return 'Direct Gift'
      }
      if (type === 5) {
        return 'Good Luck '
      }
      return 'Active'
    },
  },
}
</script>

<style>

</style>
